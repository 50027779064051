<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="col-md-6">
                    <label>Procurement title</label>
                    <div :class="{ 'has-error-field': v.form.title.$invalid }">
                        <el-input v-model="form.title"></el-input>
                    </div>
                    <div v-if="v.form.title.$invalid" class="error-text" >Procurement title is required</div>
                </div>
                <div class="col-md-6">
                    <label>Procurement reference (optional)</label>
                    <el-input v-model="form.reference"></el-input>
                </div>
                <div class="col-md-12">
                    <br/><label>Procurement description</label>
                    <div :class="{ 'has-error-field': v.form.description.$invalid }">
                        <el-input v-model="form.description" type="textarea" :row="3"></el-input>
                    </div>
                    <div v-if="v.form.description.$invalid" class="error-text" >Procurement title is required</div>
                </div>
                <!-- <div class="col-md-12" v-if="tpp_procurement_type == TPPNoticeTypes.UK2.value">
                    <div v-if="concession && utilities">
                        <br/><label>Is a dynamic market being established? (optional)</label>
                        <div class="row">
                            <el-checkbox v-model="form.is_dynamic_market" :checked="form.is_dynamic_market">Dynamic market (optional)</el-checkbox>
                        </div>
                    </div>
                    <div v-if="!concession">
                        <br/><label>Is either a dynamic market or a framework being established?  (optional)</label>
                        <div class="row">
                            <el-radio-group v-model="form.is_dynamic_market">
                                <el-radio :value="true">Dynamic market</el-radio>
                                <el-radio :value="false">Framework</el-radio>
                            </el-radio-group>
                            <br/><el-button class="col-md-4" type="primary" @click="form.is_dynamic_market = null">Clear selection</el-button>
                        </div>
                    </div>
                </div> -->
                <div class="row" v-if="tpp_procurement_type == TPPNoticeTypes.UK4.value">
                    <div class="col-md-4">
                        <br/><label>Is a framework being established?</label>
                        <div :class="{ 'has-error-field': v.form.framework_establised.$invalid }">
                            <el-radio-group v-model="form.framework_establised">
                                <el-radio-button :value="true">Yes</el-radio-button>
                                <el-radio-button :value="false">No</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.framework_establised.$invalid" class="error-text" >Yes/No is required</div>
                    </div>
                    <div class="col-md-4">
                        <br/><label>Is the procurement divided into lots?</label>
                        <div :class="{ 'has-error-field': v.form.divided_to_lots.$invalid }">
                            <el-radio-group v-model="form.divided_to_lots">
                                <el-radio-button :value="true">Yes</el-radio-button>
                                <el-radio-button :value="false">No</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.divided_to_lots.$invalid" class="error-text" >Yes/No is required</div>
                        <br/><div v-if="form.number_of_lots">
                            <br/><label>Number of Lots</label>
                            <div :class="{ 'has-error-field': v.form.number_of_lots.$invalid }">
                                <el-input v-model="form.number_of_lots"></el-input>
                            </div>
                            <div v-if="v.form.number_of_lots.$invalid" class="error-text" >Number of lots is required</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <br/><label>Are CPV classifications the same for all lots?</label>
                        <div :class="{ 'has-error-field': v.form.cpv_classifications_same.$invalid }">
                            <el-radio-group v-model="form.cpv_classifications_same">
                                <el-radio-button :value="true">Yes</el-radio-button>
                                <el-radio-button :value="false">No</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.cpv_classifications_same.$invalid" class="error-text" >Yes/No is required</div>
                    </div>
                </div>

                <div class="col-md-6" v-if="tpp_procurement_type == TPPNoticeTypes.UK2.value || form.cpv_classifications_same">
                    <br/><label>CPV classifications</label>
                    <br/><label style="color: #a9a9a9">Select all that apply. At least one code must start with 03 to 44 or 48 for goods contracts, 45 for works, or 48 to 98 for services.</label>
                    <div :class="{ 'has-error-field': v.form.cpv_codes.$invalid }">
                        <el-select v-model="form.cpv_codes" filterable clearable multiple remote :remote-method="searchCPV">
                            <el-option
                                v-for="item in optionsCpv"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                    <div v-if="v.form.cpv_codes.$invalid" class="error-text" >Classifications is required</div>
                </div>

                <div class="col-md-4" v-if="tpp_procurement_type == TPPNoticeTypes.UK4.value">
                    <br/><label>Are the delivery locations the same for all lots?</label>
                    <br/><label style="color: #a9a9a9">Select yes if delivery locations do not apply to any lot.</label>
                    <div :class="{ 'has-error-field': v.form.delivery_locations_same.$invalid }">
                        <el-radio-group v-model="form.delivery_locations_same">
                            <el-radio-button :value="true">Yes</el-radio-button>
                            <el-radio-button :value="false">No</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.delivery_locations_same.$invalid" class="error-text" >Yes/No is required</div>
                </div>

                <div class="col-md-6" v-if="form.delivery_locations_same || tpp_procurement_type == TPPNoticeTypes.UK2.value">
                    <br/><label>Delivery regions (optional)</label>
                    <br/><label style="color: #A9A9A9">select all that apply.</label>
                    <el-select v-model="form.delivery_regions" filterable clearable multiple>
                        <el-option-group v-for="(c_values, c_abbrev) in nuts_code_choices" label="Countries">
                            <el-option :value="c_abbrev" :label="c_abbrev + ': ' + c_values.name">
                                <span style="float: left">{{ c_values.name }}</span>
                                <span class="secondary-selection">
                                    {{ c_abbrev }}
                                </span>
                            </el-option>
                                <el-option-group v-if="'parts' in c_values && !form.delivery_regions?.includes(c_abbrev)" :key="c_values.name" :label="'List of ' + c_values.name" style="margin-left: 15px">
                                    <span v-for="(c1_values, c1_abbrev) in c_values.parts" style="margin-left: 15px">
                                        <el-option  :value="c1_abbrev" :label="c1_abbrev + ': ' + c1_values.name">
                                            <span style="float: left">{{ c1_values.name }}</span>
                                            <span class="secondary-selection">
                                                {{ c1_abbrev }}
                                            </span>
                                        </el-option>
                                        <el-option-group v-if="'parts' in c1_values && !form.delivery_regions?.includes(c1_abbrev)" :key="c1_values.name" :label="'List of ' + c1_values.name" style="margin-left: 15px">
                                            <span v-for="(c2_values, c2_abbrev) in c1_values.parts">
                                                <el-option :value="c2_abbrev" :label="c2_abbrev + ': ' + c2_values.name" style="margin-left: 15px">
                                                    <span style="float: left">{{ c2_values.name }}</span>
                                                    <span class="secondary-selection">
                                                        {{ c2_abbrev }}
                                                    </span>
                                                </el-option>
                                                    <el-option-group v-if="'parts' in c2_values && !form.delivery_regions?.includes(c2_abbrev)" :key="c2_values.name" :label="'List of ' + c2_values.name" style="margin-left: 15px">
                                                        <span v-for="(c3_values, c3_abbrev) in c2_values.parts">
                                                            <el-option :value="c3_abbrev" :label="c3_abbrev + ': ' + c3_values.name" style="margin-left: 15px">
                                                                <span style="float: left">{{ c3_values.name }}</span>
                                                                <span class="secondary-selection">
                                                                    {{ c3_abbrev }}
                                                                </span>
                                                            </el-option>
                                                            <el-option-group v-if="'parts' in c3_values && !form.delivery_regions?.includes(c3_abbrev)" :key="c3_values.name" :label="'Parts of ' + c3_values.name" style="margin-left: 15px">
                                                                <el-option v-for="(c4_values, c4_abbrev) in c3_values.parts" :value="c4_abbrev" :label="c4_abbrev + ': ' + c4_values.name" style="margin-left: 15px">
                                                                    <span style="float: left">{{ c4_values.name }}</span>
                                                                    <span class="secondary-selection">
                                                                        {{ c3_abbrev }}
                                                                    </span>
                                                                </el-option>
                                                            </el-option-group>
                                                        </span>
                                                    </el-option-group>
                                            </span>
                                        </el-option-group>
                                    </span>
                            </el-option-group>
                        </el-option-group >
                    </el-select>
                </div>

                <div class="col-md-12">
                    <br/><label>Total value (estimated)</label>
                    <br/><label style="color: #a9a9a9">Maximum value including all possible extensions and options. For a framework, the sum of the values of all contracts awarded under it, or under the same open framework scheme. For example, 200000 or 417709.28.</label>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label>Including VAT</label>
                        <div :class="{ 'has-error-field': v.form.including_vat.$invalid }">
                            <el-input v-model="form.including_vat"></el-input>
                        </div>
                        <div v-if="v.form.including_vat.$invalid" class="error-text" >VAT must be numeric</div>
                    </div>
                    <div class="col-md-4">
                        <label>Excluding VAT</label>
                        <div :class="{ 'has-error-field': v.form.excluding_vat.$invalid }">
                            <el-input v-model="form.excluding_vat"></el-input>
                        </div>
                        <div v-if="v.form.excluding_vat.$invalid" class="error-text" >VAT must be numeric</div>
                    </div>
                    <div class="col-md-4">
                    <label>Currency</label>
                        <el-select v-model="form.currency" filterable clearable>
                            <el-option v-for="(full_name, abbrev) in currency_choices" :value="abbrev" :label="abbrev + ': ' + full_name">{{ abbrev + ': ' + full_name }}</el-option>
                        </el-select>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4" v-if="tpp_procurement_type == TPPNoticeTypes.UK4.value">
                        <br/><label>Are contract dates the same for all lots?</label>
                        <div :class="{ 'has-error-field': v.form.contract_dates_same.$invalid }">
                            <el-radio-group v-model="form.contract_dates_same">
                                <el-radio-button :value="true">Yes</el-radio-button>
                                <el-radio-button :value="false">No</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.contract_dates_same.$invalid" class="error-text" >Yes/No is required</div>
                    </div>
                    <div class="row" v-if="tpp_procurement_type == TPPNoticeTypes.UK2.value || form.contract_dates_same">
                        <div class="col-md-4">
                            <br/><label>Contract start date (estimated)</label>
                            <br/><label style="color: #A9A9A9">For example, 01 04 2025</label>
                            <div :class="{ 'has-error-field': v.form.start_date.$invalid }" style="width: 224px">
                                <el-date-picker v-model="form.start_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                            </div>
                            <div v-if="v.form.start_date.$invalid" class="error-text" >Start date is required</div>
                        </div>
                        <div class="col-md-4">
                            <br/><label>Contract end date (estimated)</label>
                            <br/><label style="color: #A9A9A9">Without any extensions.</label>
                            <div :class="{ 'has-error-field': v.form.end_date.$invalid }" style="width: 224px">
                                <el-date-picker :disabled="!form.start_date" v-model="form.end_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disableDateAfterStartDate"></el-date-picker>
                            </div>
                            <div v-if="v.form.end_date.$invalid" class="error-text" >End date is required</div>
                        </div>
                        <div class="row">
                            <br/><label v-if="tpp_procurement_type == TPPNoticeTypes.UK2.value">Could the contract be extended? (optional)</label>
                            <label v-if="tpp_procurement_type == TPPNoticeTypes.UK4.value">Can the contract be extended?</label>
                            <div class="col-md-6">
                                <el-radio-group v-model="form.can_extend_contract" border>
                                    <el-radio :value="true">Yes</el-radio>
                                    <el-radio :value="false">No</el-radio>
                                </el-radio-group>
                                <br/><el-button type="primary" v-if="tpp_procurement_type == TPPNoticeTypes.UK2.value" @click="form.can_extend_contract = null">Clear selection</el-button>
                            </div>
                            <div v-if="form.can_extend_contract">
                                <div class="col-md-4">
                                    <br/><label>Extension end date (estimated)</label>
                                    <br/><label style="color: #A9A9A9">If all extensions are used.</label>
                                    <div :class="{ 'has-error-field': v.form.extension_end_date.$invalid }">
                                        <el-date-picker v-model="form.extension_end_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disableDatesBeforeEndDate"></el-date-picker>
                                    </div>
                                    <div v-if="v.form.extension_end_date.$invalid" class="error-text" >Extension date is required</div>
                                </div>
                                <div class="col-md-6" v-if="tpp_procurement_type == TPPNoticeTypes.UK4.value">
                                    <br/><label>Description of extensions</label>
                                    <br/><label style="color: #A9A9A9">Include the circumstances in which they could be used, and provide a breakdown if multiple extensions are possible.</label>
                                    <div :class="{ 'has-error-field': v.form.extension_description.$invalid }">
                                        <el-input v-model="form.extension_description"></el-input>
                                    </div>
                                    <div v-if="v.form.extension_description.$invalid" class="error-text" >Extension description is required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="tpp_procurement_type == TPPNoticeTypes.UK4.value">
                        <div class="col-md-6">
                            <br/><label>Does the contract include options?</label>
                            <div :class="{ 'has-error-field': v.form.include_options.$invalid }">
                                <el-radio-group v-model="form.include_options">
                                    <el-radio-button :value="0">Yes, same for all lots</el-radio-button>
                                    <el-radio-button :value="1">Yes, different for each lot</el-radio-button>
                                    <el-radio-button :value="2">No</el-radio-button>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.include_options.$invalid" class="error-text" >Yes/No is required</div>
                        </div>
                        <div class="col-md-6" v-if="form.include_options === 0">
                            <br/><label>Description of options</label>
                            <br/><label style="color: #a9a9a9">Include enough information to understand the scope of the options and the circumstances in which they could be used.</label>
                            <div :class="{ 'has-error-field': v.form.extension_description.$invalid }">
                                <el-input v-model="form.extension_description"></el-input>
                            </div>
                            <div v-if="v.form.extension_description.$invalid" class="error-text" >Description is required</div>
                        </div>
                        <div class="col-md-6">
                            <br/><label>Are award criteria the same for all lots?</label>
                            <div :class="{ 'has-error-field': v.form.award_criteria_same.$invalid }">
                                <el-radio-group v-model="form.award_criteria_same">
                                    <el-radio-button :value="true">Yes</el-radio-button>
                                    <el-radio-button :value="false">No</el-radio-button>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.award_criteria_same.$invalid" class="error-text" >Yes/No is required</div>
                        </div>
                        <div class="col-md-6">
                            <br/><label>Maximum number of lots a supplier can bid for (optional)</label>
                            <br/><label style="color: #A9A9A9">If less than the total number of lots.</label>
                            <el-input v-model="form.maximum_supplier_bid"></el-input>
                        </div>
                        <div class="col-md-6">
                            <br/><label>Maximum number of lots a supplier can be awarded (optional)</label>
                            <br/><label style="color: #A9A9A9">If less than the total number of lots.</label>
                            <el-input v-model="form.maximum_supplier_awarded"></el-input>
                        </div>
                        <div class="col-md-6">
                            <br/><label>Description of how multiple lots may be awarded (optional)</label>
                            <br/><label style="color: #A9A9A9">If less than the total number of lots.</label>
                            <el-input v-model="form.multiple_lots_desc" type="textarea" :rows="3"></el-input>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, numeric,
     requiredIf
     } from '@vuelidate/validators'
 
     const SCOPE = 'SCOPE';

     export default {
         props: ['previous_form', 'saved_form', 'is_not_submitted', 'cpv_code_choices', 'nuts_code_choices', 'currency_choices', 'tpp_procurement_type', 'concession', 'utilities'],
         data() {
             return {
                optionsCpv: [],
                listCpv: [],
                loading: false,
                form: {
                    title: null,
                    reference: null,
                    description: null,
                    is_dynamic_market: null,
                    cpv_codes: [],
                    delivery_regions: [],
                    excluding_vat: null,
                    including_vat: null,
                    currency: 'GBP',
                    start_date: null,
                    end_date: null,
                    can_extend_contract: null,
                    extension_end_date: null,
                    //UK4
                    framework_establised: null,
                    divided_to_lots: null,
                    number_of_lots: null,
                    cpv_classifications_same: null,
                    delivery_locations_same: null,
                    contract_dates_same: null,
                    include_options: null,
                    award_criteria_same: null,
                    maximum_supplier_bid: null,
                    maximum_supplier_awarded: null,
                    multiple_lots_desc: null,
                    extension_dates_same: null,
                    extension_description: null,
                    option_description: null
                },
                update: 0
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            if(this.saved_form) this.form = this.saved_form
            else this.form.currency = "GBP"

            var self = this;

            Object.values(this.cpv_code_choices).map((values) => {
                Object.entries(values).forEach((desc) => {
                    self.listCpv.push({ label: desc[0] + ": " + desc[1], value: desc[0] })
                })
            })
        },
        computed: {
        ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        validations() {
            const positive = value => value > 0

            let rules = {
                form: {
                    title: {
                        required,
                        $autoDirty: true
                    },
                    description: {
                        required,
                        $autoDirty: true
                    },
                    cpv_codes: {
                        required,
                        $autoDirty: true
                    },
                    start_date: {
                        required,
                        $autoDirty: true
                    },
                    end_date: {
                        required,
                        $autoDirty: true
                    },
                    extension_end_date: {
                        required: requiredIf(function() { return this.form.can_extend_contract}),
                        $autoDirty: true
                    },
                    including_vat: {
                        numeric,
                        $autoDirty: true
                    },
                    excluding_vat: {
                        numeric,
                        $autoDirty: true
                    }
                }
            }

            if(this.tpp_procurement_type == "UK4") {
                let uk4 = {
                    framework_establised: {
                        required,
                        $autoDirty: true
                    },
                    divided_to_lots: {
                        required,
                        $autoDirty: true
                    },
                    number_of_lots: {
                        required: requiredIf(function () {return this.form.divided_to_lots}),
                        numeric,
                        positive,
                        $autoDirty: true
                    },
                    cpv_classifications_same: {
                        required,
                        $autoDirty: true
                    },
                    delivery_locations_same: {
                        required,
                        $autoDirty: true
                    },
                    contract_dates_same: {
                        required,
                        $autoDirty: true
                    },
                    include_options: {
                        required,
                        $autoDirty: true
                    },
                    award_criteria_same: {
                        required,
                        $autoDirty: true
                    },
                    extension_dates_same: {
                        required,
                        $autoDirty: true
                    },
                    extension_description: {
                        required,
                        $autoDirty: true
                    },
                    option_description: {
                        required: requiredIf(function () { return this.form.include_options === 0}),
                        $autoDirty: true
                    },
                    extension_end_date: {
                        required: requiredIf(function () { return this.form.can_extend_contract})
                    }
                }

                rules.form = {...rules.form, ...uk4}
            }

            return rules
        },
        methods: {
            saveForm() {
                if(this.concession && this.utilities) this.is_dynamic_market = null
                this.$emit('save', SCOPE, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            },
            disablePastDates(date) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return date < today;
            },
            disableDatesBeforeEndDate(date) {
                return date < new Date(this.form.end_date);
            },
            disableDateAfterStartDate(date) {
                if(this.form.start_date)
                return date < new Date(this.form.start_date);
                else 
                return this.disablePastDates(date)
            },
            searchCPV(query) {
                if (query) {
                this.loading = true
                    setTimeout(() => {
                    this.loading = false
                    this.optionsCpv = this.listCpv.filter((item) => {
                        return item.label.toLowerCase().includes(query.toLowerCase())
                    })
                    this.optionsCpv = this.optionsCpv.slice(0, 20);
                }, 500)
                } else {
                    this.optionsCpv = []
                }
            }
        }
     }
 </script>