<template>
    <div v-if="form" class="">
      <div class="" >
        <el-switch
     
        v-if="form.id && savedUser && savedUser.enable_manual_pin"
        v-model="enable_test_status"
        size="large"
        active-text="Enable Manual PIN Activation"
        inactive-text="Disable Manual PIN Activation"
      />
      <br/>
        <form @submit.prevent.prevent="onSubmit" @reset.prevent="onCancel">
          <el-alert
            v-if="form.status == ProcurementProjectStatuses.CODE_PENDING"
            class="mb-4 "
            title="Urgent Action Required!"
            type="warning"
            description="To proceed with your procurement project, it's necessary to 
            Publish a Pin Notice. Please do so by clicking the `Publish PIN Noice` button.
            Your prompt attention to this matter is greatly appreciated."
            show-icon
            :closable="false"
          />

          <div v-if="!isNew" class="row mb-3">
            <!-- <div class="col-md-4">
              <p><i class="fa fa-info-circle text-primary"></i>&nbsp;Status: <b>{{ form.status_name }}</b></p>
              <div v-if="form.is_framework">
                <div>Framework Option:
                  <span v-if="form.category == 0">Closed Framework</span>
                  <span v-if="form.category == 1">Open Framework</span>
                </div>
              </div>
              <div v-if="!form.is_framework">
                <div>Tender Option:
                  <span v-if="form.procedure_type == 0">Open Procedure</span>
                  <span v-if="form.procedure_type == 1">Restricted Procedure</span>
                </div>
              </div>
            </div> -->
            <div class="col-md-4">
              <!-- <el-button style="margin-left: 5px;" v-if="selected.status != ProcurementProjectStatuses.CODE_PENDING"
                @click="handleOpenModal(FATNoticeTypes.F01.value)"
                type="primary"
                class="float-end"
              > -->
              <el-button style="margin-left: 5px;" v-if="selected.status != ProcurementProjectStatuses.CODE_PENDING"
                @click="handleOpenModal(TPPNoticeTypes.UK2.value)"
                type="primary"
                class="float-end"
              >
                <i class="fa fa-upload"></i>&nbsp;{{ selected.status == ProcurementProjectStatuses.CODE_PRE_PIN ? 'Publish' : 'Check published' }} PIN Notice</el-button>

              <el-button 
                v-if="selected.status == ProcurementProjectStatuses.CODE_PENDING"
                @click="setPrePINStatus"
                type="warning"
                class="float-end"
                >
                  <i class="fa fa-upload"></i>&nbsp; Initiate Pre-PIN Phase</el-button>
                  <div v-if="selected.status == ProcurementProjectStatuses.CODE_ACTIVE || selected.status == ProcurementProjectStatuses.CODE_ITT_PENDING || selected.status == ProcurementProjectStatuses.CODE_ITT || selected.status == ProcurementProjectStatuses.CODE_ITT_SUBMITTED || selected.status == ProcurementProjectStatuses.CODE_MARKING_STAGE">
                      <el-button 
                      @click="handleOpenModal(TPPNoticeTypes.UK4.value)"
                      type="success"
                      class="float-end"
                      >
                        <i class="fa fa-upload"></i>&nbsp;{{ selected.status == ProcurementProjectStatuses.CODE_ACTIVE ||
                        selected.status == ProcurementProjectStatuses.CODE_ITT_PENDING ? 
                        'Publish' : 'Check published' }} ITT
                      </el-button>
                  </div>
            </div>
  

          </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
            <label class="form-label">Type</label>
            <select 
            :class="{ 'is-invalid': v$.form.type.$errors.length, 'form-control form-control-sm': true }"
            v-model="form.type" >
                  <option v-for="procurement_type,i in procurement_types" :value="procurement_type.type_code" :key="i">{{procurement_type.type}}</option>
            </select>
            <div class="input-errors" v-for="error of v$.form.type.$errors" :key="error.$uid">
              <div class="error-text">Select Type</div>
            </div>
          </div>
          </div>
          <div class="col-md-12">

            <div class="form-group">
            <label class="form-label">Name</label>
            <input type="text" v-model.trim="form.name"
            :class="{ 'is-invalid': v$.form.name.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.name.$errors" :key="error.$uid">
              <div class="error-text">Name {{ error.$message }}</div>
            </div>
          </div>
          </div>
          <div class="col-md-12">

            <div class="form-group">
            <label class="form-label">Description</label>
            <textarea v-model.trim="form.description"
            :class="{ 'is-invalid': v$.form.description.$errors.length, 'form-control form-control-sm': true }"></textarea>
  
            <div class="input-errors" v-for="error of v$.form.description.$errors" :key="error.$uid">
              <div class="error-text">Description {{ error.$message }}</div>
            </div>
          </div>
          </div>
        </div>
      
        <div v-if="enable_test_status" class="form-group">
            <label class="form-label">Status</label>
            <select v-if="enable_test_status"
                class="form-control form-control-sm"
                v-model="form.status" >
                      <option value="ATC">Activate PIN</option>
                      <option value="ITT">Activate ITT</option>
                </select>
          </div>

          <!-- <div class="row" v-if="form.is_framework">
            <div class="col-md-4" v-if="form.id">
              <div class="form-group">
                <label class="form-label">Category</label>
                <select
                    class="form-control form-control-sm"
                    v-model="form.category" >
                          <option :value="0">Closed Framework</option>
                          <option :value="1">Open Framework</option>
                    </select>
              </div>
            </div>
          </div> -->

          <div class="row" v-if="!form.is_framework">
            <div class="col-md-4" v-if="form.id">
              <!-- <div class="form-group">
            <label class="form-label">Tender Option</label>
            <select
                class="form-control form-control-sm"
                v-model="form.procedure_type" >
                      <option :value="0">Open Procedure</option>
                      <option :value="1">Restricted Procedure</option>
                </select>
          </div> -->

          
            </div>
            <div class="col-md-4" v-if="form.id && form.is_framework">
              <!-- <div class="form-group" v-if="form.category == 1">
              <label class="form-label">Recurring Interval (In months)</label>
              <select
                  class="form-control form-control-sm"
                  v-model="recurring_interval" >
                        <option :value="1">Every Month</option>
                        <option :value="3">Every 3 Months</option>
                        <option :value="6">Every 6 Months</option>
                        <option :value="12">Yearly</option>
                        <option :value="0">Custom</option>
                  </select>
            </div> -->
            </div>
            <div class="col-md-4" v-if="!recurring_interval" >
              <div class="form-group">
                <label class="form-label">Custom Interval in months</label><br>
                <el-input-number v-model="form.recurring_interval" controls-position="right" :min="1" step-strictly></el-input-number>
              </div>
            </div>
          </div>
          <br>

          <div v-if="isNew">
          <div class="form-group">
            <label>Main Description *</label>
            <div :class="{'has-error-field': v$.form.main_description.$errors.length  }">
              <QuillEditor theme="snow" v-model:content="form.main_description" contentType="html" />
            </div>
            <div v-if="v$.form.main_description.$errors.length" class="error-text">Description is required</div>
          </div>
          <div class="form-group">
            <label>Common Files Description *</label>
            <div :class="{ 'has-error-field': v$.form.common_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.common_description" contentType="html" />
            </div>
            <div v-if="v$.form.common_description.$errors.length" class="error-text">Common files description is required</div>
          </div>
          <div class="form-group">
            <label>My Files Description *</label>
            <div :class="{ 'has-error-field': v$.form.my_files_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.my_files_description" contentType="html" />
            </div>
            <div v-if="v$.form.my_files_description.$errors.length" class="error-text">My files description is required</div>
          </div>
          <div class="form-group">
            <label>Q&A Description *</label>
            <div :class="{ 'has-error-field': v$.form.qa_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.qa_description" contentType="html" />
            </div>
            <div v-if="v$.form.qa_description.$errors.length" class="error-text">Q&A description is required</div>
          </div>
          <div class="form-group">
            <label>My Questions Description *</label>
            <div :class="{ 'has-error-field': v$.form.my_questions_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.my_questions_description" contentType="html" />
            </div>
            <div v-if="v$.form.my_questions_description.$errors.length" class="error-text">My question description is required</div>
          </div>
          <div class="form-group">
            <label>Spec Description *</label>
            <div :class="{ 'has-error-field': v$.form.spec_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.spec_description" contentType="html" />
            </div>
            <div v-if="v$.form.spec_description.$errors.length" class="error-text">Spec description is required</div>
          </div>
        </div>
  
          <div class="pt-4 text-end form-buttons" v-if="form.id">
            <button type="reset" class="btn btn-secondary" :disabled="isSaving">Cancel</button>
            <button type="submit" class="btn btn-success" :disabled="isSaving">{{isSaving ? 'Saving...' : 'Save'}}</button>
          </div>
        </form>
      </div>
    </div>

    <el-dialog
      v-model="tenderModal" 
      title="Find a tender form"
      width="100%"
      @close="tenderModal = false"
    >
    <div>
      <!-- <tender-form :fat_procurement_type="fat_procurement_type" :procurement="selected" :key="fat_procurement_type" @close-dialog="handleCloseDialog"></tender-form> -->
      <tpp-tender-form :tpp_procurement_type="tpp_procurement_type" :procurement="selected" :key="tpp_procurement_type" @close-dialog="handleCloseDialog"></tpp-tender-form>
    </div>
  </el-dialog>

    
  </template>
  
  <script>
    import { ProcurementProjects } from '@/api_services/procurement_projects';
    import { useVuelidate } from '@vuelidate/core'
    import { required, requiredIf } from '@vuelidate/validators'
    import { mapState } from 'vuex'
    import TenderForm from '@/components/pages/find_a_tender/TenderFormComponent'
    import TppTenderForm from '@/components/pages/fts_tpp/TPPTenderFormComponent'
    import Swal from 'sweetalert2';

    export default {
      emits: ["save", "cancel"],
      setup () {
        return { v$: useVuelidate() }
      },
      props: {
        selected: {
            type: Object,
            required: false
        }
      },
      components: {
        TenderForm,
        TppTenderForm
      },
      data() {
        return {
          step: 0,
          tenderModal: false,
          fat_procurement_type: null,
          tpp_procurement_type: null,
          enable_test_status: false,
          pin_modal: false,
          pin_notice: null,
          procurement_types: null,
          isSaving: false,
          recurring_interval : 1,
          form:{
            category: 0,
            recurring_interval: 1,
            type: null,
            name: null,
            description: null,
            main_description: null,
            common_description: null,
            my_files_description: null,
            qa_description: null,
            my_questions_description: null,
            spec_description: null
          }
        }
      },
      validations() {
        return {
          form: {
            type: {
              required,
              $autoDirty: true
            },
            name: {
              required,
              $autoDirty: true
            },
            description: {
              required,
              $autoDirty: true
            },
            spec_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            my_questions_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            qa_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            my_files_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            main_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            common_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
          },
        }
      },
      created: function () {
        this.form = Object.assign({}, this.selected)
        this.getProcurementProjectTypes()
        if (!this.form.id) {
          if (!this.form.recurring_interval) this.form.recurring_interval = 1
          else this.recurring_interval = this.form.recurring_interval
        }
      },
      watch: {
        selected() {
          this.form = Object.assign({}, this.selected)
          if (!this.form.recurring_interval) this.form.recurring_interval = 1
        },
        'form.main_description': function () {
          if (this.form.main_description == '<p><br></p>') this.form.main_description = null
        },
        'form.common_description': function () {
          if (this.form.common_description == '<p><br></p>') this.form.common_description = null
        },
        'form.my_files_description': function () {
          if (this.form.my_files_description == '<p><br></p>') this.form.my_files_description = null
        },
        'form.qa_description': function () {
          if (this.form.qa_description == '<p><br></p>') this.form.qa_description = null
        },
        'form.my_questions_description': function () {
          if (this.form.my_questions_description == '<p><br></p>') this.form.my_questions_description = null
        },
        'form.spec_description': function () {
          if (this.form.spec_description == '<p><br></p>') this.form.spec_description = null
        },
        form: function(){
          this.$emit('update', this.form)
        }
      },
      computed: {
        title() {
          return this.isNew ? 'Add New' : `Manage Project`
        },
        isNew() {
          return !this.selected?.id
        },
        ...mapState(['ProcurementProjectStatuses', "FATNoticeTypes", 'TPPNoticeTypes', 'savedUser'])
      },
      methods: {
        setPrePINStatus(){
          Swal.fire({
            title: 'Initiate Pre-PIN Phase?',
            text: 'By clicking continue, you\'re updating this project into pre-PIN phase. Do you want to proceed?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              this.globalState.loading = true

              this.form.status = this.ProcurementProjectStatuses.CODE_PRE_PIN

              ProcurementProjects.update(this.selected.id, this.form)
              .then(result => {
                this.$toast.success('Successfully updated to Pre-PIN Phase')
                this.$emit('save', this.form)
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                this.globalState.loading = false
              })
              
            }
          });
        },
        getProcurementProjectTypes(){
            ProcurementProjects.getProcurementProjectTypes()
            .then(resp => {
                this.procurement_types = resp.data.procurement_project_types
            })
            .catch(error => {
                this.$toast.error('An error occured while getting procurement project types')
                console.error('error:', error.response.data)
            })
        },
        async onSubmit(evt) {
  
          evt.preventDefault()
  
          const result = await this.v$.$validate()
          if (!result) return
  
          this.isSaving = true
          const promise = this.isNew ? ProcurementProjects.create(this.form) : ProcurementProjects.update(this.selected.id, this.form)
          promise
            .then(resp => {
              
              this.$toast.success(resp.data.message || 'Procurement Project has been saved successfully')
              this.$emit('save', this.form)
            })
            .catch(error => {
              console.error('error:', error.response.data)
            })
            .finally(() => {
              this.isSaving = false
            })
        },
        onCancel() {
          this.$emit('cancel')
        },
        handleOpenModal(procurement_type) {
          this.tenderModal = true;
          this.tpp_procurement_type = procurement_type
          this.fat_procurement_type = procurement_type;
        },
        handleCloseDialog() {
          var self = this
          this.tenderModal = false;
          this.onCancel()
          setTimeout(() => {self.$emit("reload")}, 5000);
        }
      }
    }
  </script>
  