<template>
  <div v-if="$store.state.checkUserGroupModuleRestriction('framework-lot-mod') != 1"> 

    <el-drawer title="" v-model="showLot" direction="rtl" :destroy-on-close="true" :append-to-body="true" size="90%"
      :before-close="getLots">
      <supplierhub-lot-page @updateOpenSpecListKind="open_spec_list_kind = 2" :open_spec_list_kind="open_spec_list_kind" @reOpenLot="reOpenLot" :lot="selected" v-if="selected" :pin_notice_id="pin_notice_id"
        :itt_framework="itt_framework" :itt_framework_id="itt_framework_id" :if_pin_notice="if_pin_notice"
        :allowCreate="allowCreate()" :joined="!checkIfNotJoined()"></supplierhub-lot-page>
    </el-drawer>

    <el-drawer title="" v-model="showAdd" direction="rtl" :destroy-on-close="true" :append-to-body="true">
      <supplierhub-lot-form @close="getLots()" :lot="selected" :pin_notice_id="pin_notice_id"
        :itt_framework_id="itt_framework_id" :if_pin_notice="if_pin_notice" v-if="showAdd"></supplierhub-lot-form>
    </el-drawer>

    <el-drawer title="" v-model="showView" direction="rtl" size="70%" :destroy-on-close="true" :append-to-body="true">
      <supplierhub-lot-view @close="getLots()" :lot="selected" :itt_framework="itt_framework"
        :pin_notice_id="pin_notice_id" :itt_framework_id="itt_framework_id" :if_pin_notice="if_pin_notice" :kind="kind"
        v-if="selected && showView"></supplierhub-lot-view>
    </el-drawer>

    <div style="text-align: left;" class="row" v-if="itt_framework">
      <div class="col-md-12">
        <div class=" my-3">
          <div class="card-body px-0 pb-2">
            <div class="px-4">
              <h4>Framework Lots</h4>
              <div v-html="itt_framework.spec_description"></div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <el-button plain type="primary" @click="getLots()" size="small"
                  style="margin-left: 10px;">Refresh</el-button>
              </div>
              <div class="col-md-12">
                <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                  <el-form :inline="true" class="demo-form-inline" @submit.prevent="getLots($event)">
                    <el-form-item>
                      <el-button type="success" @click="import_modal = true" size="small" 
                      v-if="
                      ($store.state.checkUserGroupPermissions('framework-lots', 'import', false, false, '', '', [], true) == 1) ||
                      ($store.state.checkUserGroupPermissions('framework-lots', 'import', false, false, '', '', [], true) == 0 && allowCreate())"><i
                          class="fa-solid fa-upload"></i>&nbsp;
                        Import Lot
                      </el-button>
                      <el-button type="primary" @click="create()" size="small" 
                      v-if="
                      ($store.state.checkUserGroupPermissions('framework-lots', 'create', false, false, '', '', [], true) == 1) ||
                      ($store.state.checkUserGroupPermissions('framework-lots', 'create', false, false, '', '', [], true) == 0 && allowCreate())"
                      ><i
                          class="fa-solid fa-plus"></i>
                        Create Lot
                      </el-button>
                    </el-form-item>
                  </el-form>
                </nav>
              </div>
            </div>

            <div 
            v-if="$store.state.checkUserGroupPermissions('framework-lots', 'view_list', true)"
            class="table-responsive" style="max-height: 500px;" v-loading="loading">
              <el-table class="table-striped" header-row-class-name="text-primary" :data="lots" style="width: 100%">
                <el-table-column :min-width="40" class-name="td-actions" label="Lot Number">
                  <template v-slot="props">
                    {{ (props.row.lot_no ? "Lot " + props.row.lot_no : "") }}
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" class-name="td-actions" label="Name">
                  <template v-slot="props">
                    {{ props.row.name }}
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" class-name="td-actions" label="Action">
                  <template v-slot="props">


                    <el-tooltip v-if="$store.state.checkUserGroupPermissions('framework-lots', 'view_details', true)" :enterable="false" placement="top-start">
                      <template #content>
                        <span>View Detals</span>
                      </template>
                      <el-button size="small" type="primary" @click="handleShow(props.row)">View Details</el-button>

                    </el-tooltip>

                    <el-tooltip :enterable="false" placement="top-start" 
                    v-if="
                      ($store.state.checkUserGroupPermissions('framework-lots', 'edit', false, false, '', '', [], true) == 1) ||
                      ($store.state.checkUserGroupPermissions('framework-lots', 'edit', false, false, '', '', [], true) == 0 && allowCreate())"
                    >
                      <template #content>
                        <span>Edit</span>
                      </template>
                      <el-button size="small" @click="handleEdit(props.row)">
                        <i class="fas fa-pencil"></i>
                      </el-button>
                    </el-tooltip>

                    <el-tooltip :enterable="false" placement="top-start" 
                    v-if="
                      ($store.state.checkUserGroupPermissions('framework-lots', 'delete', false, false, '', '', [], true) == 1) ||
                      ($store.state.checkUserGroupPermissions('framework-lots', 'delete', false, false, '', '', [], true) == 0 && allowCreate())"
                    >
                      <template #content>
                        <span>Delete</span>
                      </template>

                      <el-button size="small" type="danger" @click="handleDelete(props.row)"><i
                          class="fas fa-trash"></i></el-button>
                    </el-tooltip>

                  </template>
                </el-table-column>
              </el-table>
              <br>
              <pagination :meta="meta" v-on:update="getLots()" />

            </div>
            <div v-else>
              <el-empty description="Your group doesnt have access to this list!" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <el-empty description="Your group doesn't have access to this module!" />
  </div>

  <el-dialog destroy-on-close v-model="import_modal" title="Upload File" width="500">
    <form @submit="uploadFile" v-loading="loading">
      <p>Please upload an .xlsx file. You can use the below template for the importing.<br>

        <el-button-group>
          <el-link class="me-3" type="success" link href="/template/import_supplier_hub_lots_template_official.xlsx">Sample Excel
            Template</el-link>
        </el-button-group>
      </p>

      <dropzone label="Drop or select file" :max_files="1" :clean="0" :multiple="false" extensions="'xlsx,xls'"
        @input="setFile($event)"></dropzone>
      <button type="submit" class="btn btn-success" v-loading="loading" :disabled="!import_form.file">
        {{ loading ? 'Saving' : 'Save' }}
      </button>
    </form>
  </el-dialog>

  <el-dialog destroy-on-close id="error-modal" :close-on-click-modal="false" v-model="error_modal" width="50%">
    <ul class="p-4 list-group">
      <template v-for="(message,i) in error_message" :key="i">
        <li v-if="message != '' " class="list-group-item"><i class="text-danger fa fa-info-circle"></i>&nbsp;{{ message
          }}</li>
      </template>
    </ul>
  </el-dialog>

</template>

<script>
import { SupplierhubLot } from '@/api_services/supplierhub_lot'
import { mapState } from 'vuex'
import SupplierhubLotForm from './SupplierhubLotForm.vue'
import SupplierhubLotView from './SupplierhubLotView.vue'
import Swal from 'sweetalert2'
import SupplierhubLotPage from './SupplierhubLotPage.vue'
import Dropzone from '@/components/common/Dropzone'

export default {
  components: { SupplierhubLotForm, SupplierhubLotView, SupplierhubLotPage, Dropzone },
  props: {
    itt_framework: {
      type: Object,
      required: false,
      default: null
    },
    if_pin_notice: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      re_open_lot: 0,
      error_message: [],
      error_modal: false,
      import_form: {},
      import_modal: false,
      showLot: false,
      company_ids: [],
      itt_framework_id: null,
      pin_notice_id: null,
      showAdd: false,
      showImport: false,
      lots: [],
      term: null,
      status: 1,
      loading: false,
      selected: null,
      showView: false,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      kind: 0
    }
  },
  watch: {
    status: function() {
      this.getLots()
    }
  },
  computed: {
    ...mapState(['savedUser']),
    this_user() {
      return this.$store.state.savedUser 
    }
  },
  created: function() {
    if(this.if_pin_notice == 1){
      this.pin_notice_id = this.itt_framework?.id
    }
    else{
      this.itt_framework_id = this.itt_framework?.id
    }

    this.status = this.savedUser.is_supplier_hub_admin ? 1 : 0
    this.getLots()

    for (const [key, value] of Object.entries(this.this_user.companies)) {
      this.company_ids.push(value.id)
    }
  },
  methods: {
    reOpenLot({data, kind}){
      this.re_open_lot = 1
      this.open_spec_list_kind = kind
      this.getLots(data)
    },
    uploadFile(evt) {
          evt.preventDefault()

          var formData = new FormData()
          formData.append('file', this.import_form.file)
          if(this.if_pin_notice == 1){
            formData.append('pin_notice_id', this.itt_framework?.id)
          }
          else{
            formData.append('itt_framework_id', this.itt_framework?.id)
          }

          this.loading = true

          SupplierhubLot.importSupplierHubLots(formData)
            .then(result => {
              if(result.data.status == 1 || result.data.status == 2){
                result.data.status == 1 ? this.$toast.success(result.data.message) : this.$toast.warning(result.data.message)
                this.meta.page = 1
                this.getLots()
              }
              else{
                this.$toast.error('Failed to import')
                this.error_message = result.data.message.split(',')
                this.error_modal = true
              }

              this.import_modal = false
              this.import_form.file = null
            })
            .catch(error => {

            }).finally(() => {
              this.loading = false
          })
    },
    setFile(files) {
      this.import_form.file = files[0]
    },
    handleClose() {
      this.showLot = false
      this.showAdd = false
    },
    handleShow(lot) {
      this.selected = Object.assign({}, lot)
      this.showLot = true
    },
    allowCreate() {
      if (this.itt_framework && this.if_pin_notice) {
        let allowed_statuses = ['PRE', 'ATC']
        if (this.itt_framework.procurement_project && (this.$store.state.isUserAnOriginator()) && allowed_statuses.includes(this.itt_framework.procurement_project.status)) {
          return true
        }
      }

      let condition = (this.itt_framework.is_deadline_passed && this.$store.state.isUserAnOriginator() && !this.itt_framework.is_archived) ||
        ((!this.itt_framework.is_deadline_passed && !this.itt_framework.is_archived && this.savedUser.is_supplier_hub_admin) &&
          (this.$store.state.isUserASupplier() && this.if_pin_notice == 1) || (!this.$store.state.isUserASupplier())
      )
      if (this.itt_framework && this.if_pin_notice) {
        if (this.itt_framework.read_only_from_status) return false
      }
      return condition
    },
    checkIfNotJoined(){
      if(
        !this.itt_framework.if_user_joined && 
        this.itt_framework?.user?.id != this.this_user.id &&
        this.this_user?.company?.id != this.itt_framework?.company?.id &&
        !this.company_ids.includes(this.itt_framework?.company?.id)
      ){
        return true
      }
      return false
    },
    handleDelete(lot){
      this.loading = true

      let type = "itt"
      if(this.if_pin_notice == 1){
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to delete this lot!",
          icon: 'warning',
        })
        return false
      }

      Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this data. This action cannot be undone!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          SupplierhubLot.delete(lot.id, type)
          .then(resp => {
            this.getLots()
            this.$toast.success(resp?.data?.message || 'Framework Lot has been removed successfully')
          })
          .catch(error => {
            this.$toast.error('An error occured while deleting invoice')
            console.error('error:', error.response.data)
          })
          .finally(() => {
            this.loading = false
          })
        }
      })
    },
    handleView(lot, kind) {
      this.selected = Object.assign({}, lot)
      this.showView = true
      this.kind = kind
    },
    handleEdit(lot) {
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to edit this lot!",
          icon: 'warning',
        })
        return false
      }

      this.selected = Object.assign({}, lot)
      this.showAdd = true
    },
    create() {
      let type = "itt"
      if (this.if_pin_notice == 1) {
        type = "pin"
      }

      if(this.checkIfNotJoined()){
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to add a lot!",
          icon: 'warning',
        })
        return false
      }

      this.showAdd = true
    },
    search() {
      this.meta.page = 1
      this.getLots()
    },
    getLots(returned_data = null) {
      this.handleClose()
      this.showAdd = false
      let params = {
        page: this.meta.page,
        term: this.term,
        itt_framework_id: this.itt_framework?.id,
        if_pin_notice: this.if_pin_notice
      }
      this.loading = true
      this.selected = null
      SupplierhubLot.get({
        params: params
      })
        .then(result => {
          if (result.data.lots) this.lots = result.data.lots
          this.meta = result.data.meta

          if(this.re_open_lot == 1){
            this.showLot = false
            this.selected = Object.assign({}, this.lots.find(obj => obj.id === returned_data.id))
            this.showLot = true
            this.re_open_lot = 0
          }

          this.loading = false
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching lots')
          console.error('error:', error.response.data)
        })
        .finally((e) => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>
.completed {
  background: #d3ffe3;
}
</style>
