<template>

  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>Project Timeline: ({{ procurement_project.name }})</h5>
            <div class="pb-0 mt-5">
              <div class="row">
                <div class="col-md-2">
                  <el-input v-on:keyup.enter="loadTimelines" v-model="search_value" placeholder="Search"
                    class="input-with-select">
                    <template #append>
                      <el-button @click="loadTimelines"><i class="fa fa-search"></i></el-button>
                    </template>
                  </el-input>
                </div>
                <div class="col-md-10" style="text-align: right;">
                  <button type="button" class="btn btn-info btn-sm mb-0 me-2" @click="timeline_view_drawer = true">
                    <i class="fa-solid fa-timeline me-1"></i> View Timeline Mode
                  </button>
                  <button type="button" class="btn bg-gradient-secondary btn-sm mb-0 me-2" @click="loadTimelines">
                    <i class="fa-solid fa-arrow-rotate-right me-1"></i> Reload
                  </button>
                  <button type="button" class="btn btn-primary btn-sm mb-0 me-2" @click="addTimeline">
                    <i class="fa-solid fa-plus me-1"></i> Add
                  </button>
                </div>
              </div>
              <div class="text-end">
              </div>
            </div>
            <br>
            <div class="table-responsive" v-loading="loading">
              <table class="table table-fluid align-items-center mb-0 table-hover ">
                <thead>
                  <tr>

                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Name
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Description
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Type
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Start Date
                    </th>

                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      End Date
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Created At
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Status
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!timelines.length">
                    <td v-if="loading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No data</td>
                  </tr>
                  <tr v-else class="text-sm" v-for="timeline in timelines" :key="timeline.id">

                    <td>{{ timeline.name }}</td>
                    <td class="text-wrap">{{ timeline.description }}</td>
                    <td>
                      <template v-if="timeline.type == 0">Procurement Project</template>
                      <template v-if="timeline.type == 1">Pre-PIN</template>
                      <template v-if="timeline.type == 2">PIN</template>
                      <template v-if="timeline.type == 3">ITT</template>
                    </td>
                    <td>{{ $filters.dateFormatFilter(timeline.start_date) }}</td>
                    <td>{{ $filters.dateFormatFilter(timeline.end_date) }}</td>
                    <td>{{ $filters.dateFormatFilter(timeline.created_at) }}</td>
                    <td>
                      <template v-if="timeline.status == 0"><a-tag color="gray">Pending</a-tag></template>
                      <template v-if="timeline.status == 1"><a-tag color="skyblue">On-Going</a-tag></template>
                      <template v-if="timeline.status == 2"><a-tag color="green">Done</a-tag></template>
                      <template v-if="timeline.status == 3"><a-tag color="red">Canceled</a-tag></template>
                    </td>
                    <td>
                      <el-button-group>
                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>Edit</span>
                          </template>

                          <el-button v-allowed="'procurement_project_timeline,update'" type="primary" size="small"
                            @click="editTimeline(timeline)">
                            <i class="fa fa-edit"></i></el-button>
                        </el-tooltip>

                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>Delete</span>
                          </template>

                          <el-button v-allowed="'procurement_project_timeline,delete'" type="danger" size="small"
                            @click="deleteTimeline(timeline.id)">
                            <i class="fa fa-trash"></i></el-button>
                        </el-tooltip>
                      </el-button-group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="loadTimelines()" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <el-dialog destroy-on-close id="add-modal" :close-on-click-modal="false" v-model="add_modal" width="60%">
    <TimelineForm :project_id="procurement_project.id" :key="'timeline_' + selected.id" @save="afterSave" @cancel="handleClose" :selected="selected">
    </TimelineForm>
  </el-dialog>

  <el-drawer v-model="timeline_view_drawer" :show-close="true" size="50%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div class="container">
      <TimelineView :type="4" :procurement_project="procurement_project"></TimelineView>
    </div>
  </el-drawer>

</template>
    
    <script>

    import TimelineForm from './TimelineForm.vue'
    import TimelineView from './TimelineView.vue'
    import { ProcurementProjectTimeline } from '@/api_services/procurement_project_timeline';
    import Swal from 'sweetalert2'

    export default {
      props: {
        procurement_project: {
          type: Object,
          required: false,
          default: []
        },
      },
      computed: {
        user () {
          return this.$store.state.savedUser // get state
        }
      }, 
      components: {
        TimelineForm, TimelineView
      },
      data() {
        return {
            timeline_view_drawer: false,
            search_value: "",
            add_modal: false,
            timelines: [],
            meta: {
              page: 1,
              total: 0,
              pages: 1
            },
            selected: null,
            loading: false
        }
      },
      watch: {
      },
      created: function () {
        this.loadTimelines()
      },
      methods: {
        deleteTimeline(id) {

          Swal.fire({
            icon: 'info',
              text:
                'You are about to delete this timeline, are you sure you are going to proceed?',
              showCancelButton: true,
              confirmButtonText: 'Proceed',
              cancelButtonText: 'Cancel',  
          }).then(result => {
              if (result.isConfirmed) {

                this.globalState.loading = true
                ProcurementProjectTimeline.delete({ id: id})
                  .then(result => {
                    this.$toast.success(result?.data?.message || 'Timeline has been removed successfully')
                    this.loadTimelines()
                    
                    this.handleClose()
                  })
                  .catch(error => {
                      this.$toast.error('An error occured while deleting timeline')
                      console.error('error:', error.response.data)

                  }).finally(() => {
                    this.globalState.loading = false
                  })
                
              }
          })
        },
        editTimeline(timeline){
          this.selected = timeline
          this.add_modal = true
        },
        addTimeline(){
          this.selected = { id: null }
          this.add_modal = true
        },
        handleClose() {
          this.add_modal = false
        },
        afterSave() {
          this.loadTimelines()
          this.add_modal = false
        },
        loadTimelines(){
          this.globalState.loading = true
          this.handleClose()
          ProcurementProjectTimeline.get({
              params: {
                page: this.meta.page,
                search_value: this.search_value,
                project_id: this.procurement_project.id
              }
            })
            .then(result => {
              if (result.data.timelines) this.timelines = result.data.timelines
              this.meta = result.data.meta
            })
            .catch(error => {
    
            })
            .finally(() => {
              this.globalState.loading = false
            })
        }
      },
    }
    </script>
    